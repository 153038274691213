import React, { useEffect } from 'react';
import { Typography, withWidth } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/Maximize';
import useStyles from '../useStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ContactlessIcon from '@material-ui/icons/Contactless';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import TextsmsIcon from '@material-ui/icons/Textsms';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import EmailIcon from '@material-ui/icons/Email';

const Servicios = (props) => {
    const classes = useStyles();

    useEffect(() => {
      window.scrollTo( 0,0 );
    },[])
    
    return (
      <div className={classes.root}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper1}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  IVRs Masivos
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <DevicesOtherIcon style={{ fontSize: '100px', color:'#2C7744' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon className={classes.subraya}/>
              </Typography>                          
            </Box>                          
            <Typography display="block"  variant="h6" className={classes.texto}>
              Audios masivos a través de nuestra plataforma de envío Cloud. Los audios pueden ser con tonos DTMF y 
              Press One (es decir, marque 1). También pueden tener retorno a una central o anexo configurado.
            </Typography>
          </Paper>
        </Grid>        
        <Grid item xs={12} sm={6} md={3} >
          <Paper className={classes.paper3}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  SMS Masivo
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <TextsmsIcon  style={{ fontSize: '100px' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon style={{ textAlign:'initial', fontSize: 80, color:'#cc6714' }}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>
              Mensajería Masiva con reporteria online y programación de envíos para temas de cobranzas, ventas y encuentas.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper2}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  Telefonia
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <DeviceHubIcon style={{ fontSize: '100px', color:'#aa263a' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon style={{ textAlign:'initial', fontSize: 80, color:'#aa263a' }}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>
              Contamos con los mejores precios del mercado para llamadas locales, nacional e internacionales. 
              Todo ello soportado con los mejores ruteos a través de nuestras estratégicas interconexiones a nivel mundial.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper7}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  Wholesale termination
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <DeveloperModeIcon style={{ fontSize: '100px' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon style={{ textAlign:'initial', fontSize: 80, color:'#cc6714' }}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>
            A través de nuestro producto AZ, contamos con llamadas desde Afganistan hasta Zimbabwe con nuestras calidades Premium y 
            Standard acorde al requerimiento del cliente. Contamos con estrategicas interconexiones locales 
            e interconectados con los principales incumbentes locales de Latinoamerica.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Paper className={classes.paper6}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1} className={classes.iconEmail}>
                <Typography variant="h4" className={classes.titulo}>
                  Emailing
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <EmailIcon style={{ fontSize: '100px', color:'aa263a'}} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon style={{ textAlign:'initial', fontSize: 80, color:'#aa263a' }}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>
              Contamos con envíos de correos masivos para temas de cobranzas, ventas y encuestas.
            </Typography>
          </Paper>
        </Grid>        
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper5}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  DID Numbers
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <ContactlessIcon style={{ fontSize: '100px', color:'2C7744' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon className={classes.subraya}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>Contamos con Numeracion Fija Virtual Local, 
            Nacional e internacional para rediccionarlo a una PBX/Mobile o enmascaramiento de llamadas outbound.
            </Typography>
          </Paper>
        </Grid>        
        <Grid item xs={12} sm={6} md={6}>
          <Paper className={classes.paper4}>
            <Box display="flex" className={classes.boxInPaper}>
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.titulo}>
                  Random de llamadas
                </Typography>                          
              </Box>
              <Box p={1} className={classes.icon}>
                <DialerSipIcon style={{ fontSize: '100px' }} />
              </Box>              
            </Box>            
            <Box flexGrow={1}>
              <Typography variant="h4" className={classes.subraya}>
                <MaximizeIcon style={{ textAlign:'initial', fontSize: 80, color:'#cc6714' }}/>
              </Typography>                          
            </Box>                          
            <Typography display="block" gutterBottom={true} variant="h6" className={classes.texto}>
            Sistema que permite ruletear enmascaramientos para los Caller ID (cabeceras de llamadas) hasta con
             1000 numeros dedicados generando una mejor perfomance en la contactabilidad.
            </Typography>
          </Paper>
        </Grid>        
      </Grid>
    </div>        
    )
}

export default withWidth()(Servicios);