import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MaximizeIcon from '@material-ui/icons/Maximize';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import '../../App.css';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import PhoneIcon from '@material-ui/icons/Phone';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';


const useStyles = makeStyles((theme) => ({
    root: {
        overflow:'hidden',
        margin:'90px 0px 30px 0px',
        [theme.breakpoints.only('xs')]: {
            margin:'90px 0px 15px 0px',
        },
    },
    box1: {
        height:'420px',
        padding:'30px 40px 0px 40px',
        color:'#fff',
        transition:'all 1s',
        background:'#2c3846',
        '&:hover': {
            background:'#827717',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.only('md')]: {
            padding:'20px 30px 0px 30px',
            height:'380px',
        },
        [theme.breakpoints.only('sm')]: {
            padding:'5px 15px 0px 15px',
            
        },
        [theme.breakpoints.only('xs')]: {
            padding:'10px 15px 0px 15px',
            height:'375px'
        },

        
    },
    box2: {
        height:'420px',
        padding:'30px 70px 0px 70px',
        color:'#fff',
        transition:'all 1s',
        background:'#2c3846',
        '&:hover': {
            background:'#16a085',
            transition:'all 1s',
            cursor:'pointer',
            
        },
        [theme.breakpoints.only('md')]: {
            padding:'20px 20px 0px 20px', 
            height:'380px',
        },
        [theme.breakpoints.only('sm')]: {
            padding:'5px 15px 0px 15px',     
        },
        [theme.breakpoints.only('xs')]: {
            padding:'10px 15px 0px 15px',
            
        },
        
    },
    box3: {
        height:'420px',
        padding:'30px 70px 10px 70px',
        color:'#fff',
        transition:'all 1s',
        background:'#2c3846',
        '&:hover': {
            background:'#aa263a',
            transition:'all 1s',
            cursor:'pointer', 
        },
        [theme.breakpoints.only('md')]: {
            padding:'20px 20px 0px 20px', 
        },
        [theme.breakpoints.only('sm')]: {
            padding:'5px 15px 0px 15px', 
        },
        [theme.breakpoints.only('xs')]: {
            padding:'10px 15px 0px 15px',
            
        },
        
    },
    box4: {
        height:'420px',
        padding:'30px 30px 10px 30px',
        color:'#fff',
        transition:'all 1s',
        background:'#2c3846',
        '&:hover': {
            background:'#827717',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.only('md')]: {
            padding:'20px 20px 0px 20px', 
        },
        [theme.breakpoints.only('sm')]: {
            padding:'5px 15px 0px 15px',
            
        },
        [theme.breakpoints.only('xs')]: {
            padding:'10px 15px 0px 15px',
            
            
        },
        
    },
    // box5: {
    //     height:'420px',
    //     padding:'30px 30px 10px 30px',
    //     color:'#fff',
    //     transition:'all 1s',
    //     background:'#2c3846',
    //     '&:hover': {
    //         background:'#16a085',
    //         transition:'all 1s',
    //         cursor:'pointer',
    //     },
    //     [theme.breakpoints.only('md')]: {
    //         padding:'20px 10px 0px 10px',
    //         height:'390px',
    //     },
    //     [theme.breakpoints.only('sm')]: {
    //         padding:'5px 15px 0px 15px',
            
    //     },
    //     [theme.breakpoints.only('xs')]: {
    //         padding:'10px 30px 0px 15px',
            
    //     },

        
    // },
    box6: {
        height:'420px',
        padding:'30px 70px 10px 70px',
        color:'#fff',
        transition:'all 1s',
        background:'#2c3846',
        '&:hover': {
            background:'#aa263a',
            transition:'all 1s',
            cursor:'pointer', 
        },
        [theme.breakpoints.only('md')]: {
            padding:'20px 20px 0px 20px',
            height:'390px',
        },
        [theme.breakpoints.only('sm')]: {
            padding:'5px 15px 0px 15px',
            
        },
        [theme.breakpoints.only('xs')]: {
            padding:'10px 15px 0px 15px',
            height:'530px',
            
        },
    },
    boxCont: {
        display:'flex',
        // [theme.breakpoints.only('xs')]: {
        //     display:'inline-flex',
        // },
    },
    servIcon1: {
        textAlign:'initial',
        paddingRight:'25px',
        
    },
    titulo1: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'30px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'29px'
        },
    },
    titulo2: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'28px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px'
        },
        
    },
    titulo3: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'30px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px'
        },
    },
    titulo4: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'28px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px'
        },
    },
    titulo5: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'20px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px'
        },
    },
    titulo6: {
        textAlign:'initial',
        paddingTop:'30px',
        fontWeight:600,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'28px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px'
        },
    },
    icon1: {
        color:'#16a085',
        fontSize:80,
        [theme.breakpoints.only('md')]: {
            marginBottom:'-20px'
        },
    },
    icon2: {
        color:'#aa263a',
        fontSize:80,
    },
    icon3: {
        color:'#cc6714',
        fontSize:80,
    },
    icon4: {
        color:'#16a085',
        fontSize:80,
    },
    icon5: {
        color:'#aa263a',
        fontSize:80,
    },
    icon6: {
        color:'#cc6714',
        fontSize:80,
    },
    iconRight1: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#16a085'
    },
    iconRight2: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#aa263a'
    },
    iconRight3: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#cc6714'
    },
    iconRight4: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#16a085'
    },
    iconRight5: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#aa263a'
    },
    iconRight6: {
        fontSize:'100px',
        paddingRight:'20px',
        color:'#cc6714'
    },
    texto1: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('sm')]: {
            fontSize:'18px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'20px'
        },
    },
    texto2: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('md')]: {
            fontSize:'19px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'16px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'17px'
        },
        
    },
    texto3: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('md')]: {
            fontSize:'19px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'16px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'17px'
        },
    },
    texto4: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('md')]: {
            fontSize:'17px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'15px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'17px'
        },
        
    },
    texto5: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('sm')]: {
            fontSize:'15px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'20px',
        },
    },
    texto6: {
        fontFamily: 'Ubuntu, sans-serif',
        textAlign:'justify',
        [theme.breakpoints.only('md')]: {
            fontSize:'19px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'16px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'17px'
        },
    },


}));



const Sistemas = () => {

    const classes = useStyles()

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item xs={12} sm={6} md={5} lg={4} >
                    <Paper className={classes.box1} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1} flexGrow={1}>
                                <Typography variant="h4" className={classes.titulo1}>Robot de llamadas</Typography>
                                <MaximizeIcon className={classes.icon1}/>
                            </Box>
                            <Box>
                                <PhoneAndroidIcon className={classes.iconRight1}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" className={classes.texto1}>El futuro de reemplazar con "agentes virtuales-robots" a través del reconocimiento de la voz 
                        y entregar respuestas a través de los sistemas TTs o STT.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} >
                    <Paper className={classes.box2} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1} flexGrow={1}>
                                <Typography variant="h4" className={classes.titulo2}>Validador de llamadas</Typography>
                                <MaximizeIcon className={classes.icon2}/>
                            </Box>
                            <Box>
                                <OfflinePinIcon className={classes.iconRight2}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" className={classes.texto2}>Depuramos data a gran escala y extraer los números prendidos y apagados y a su vez entregar 
                        los status de los números según el reconocimiento del AMD. Depuramos un aproximado de 10mil números por hora a traves de nuestra plataforma cloud.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={5} >
                    <Paper className={classes.box3} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1} flexGrow={1}>
                                <Typography variant="h4" className={classes.titulo3}>Webphone</Typography>
                                <MaximizeIcon className={classes.icon3}/>
                            </Box>
                            <Box>
                                <PhoneIcon className={classes.iconRight3}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" className={classes.texto3}>Nuestro Webphone es una web practica y útil que permite tener mejor calidad en la llamada, 
                        poder usarlo en cualquier lugar sin necesidad de descargar el software. Es decir, el teléfono funciona solo teniendo un navegador.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5} >
                    <Paper className={classes.box4} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1} flexGrow={1}>
                                <Typography variant="h4" className={classes.titulo4}>Business Intelligence</Typography>
                                <MaximizeIcon className={classes.icon4}/>
                            </Box>
                            <Box>
                                <BusinessCenterIcon className={classes.iconRight4}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" className={classes.texto4}>Manejamos un BI personalizado para cada unos de nuestros clientes. 
                        En este escenario el cliente podrá saber y tener en tiempo real reportes estadísticos de contactabilidad, Consumos y centro 
                        de costos de cada una de sus carteras mapeadas en nuestra plataforma de Telefonía.</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={9} md={8} lg={8} >
                    <Paper className={classes.box6} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1} flexGrow={1} >
                                <Typography variant="h4" className={classes.titulo6}>Marcador Predictivo</Typography>
                                <MaximizeIcon className={classes.icon6}/>
                            </Box>
                            <Box>
                                <AddToHomeScreenIcon className={classes.iconRight6}/>
                            </Box>
                        </Box>
                        <Typography variant="h6" className={classes.texto6}>Contamos con marcadores inhouse cloud y customizados acorde al requerimiento de nuestros 
                        clientes personalizando cada uno de ellos según su nicho de mercado (ventas, cobranzas, encuentas) al cual apunta. Nuestros sistemas son inductivos, 
                        amigables y dinámicos interactuando siempre con el AMD para el reconocimiento de voz y la intensidad de llamadas de cara a la contactabilidad de llamadas.</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Sistemas;
