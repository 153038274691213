import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListasRight from './ListasRight';
import '../App.css';


const useStyles = makeStyles(( theme ) => ({
    root: {
        width: 240,
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        fontFamily: 'Ubuntu, sans-serif',
    },
    
    drawerPaper: {
        width: 240,
        background:'#232d38',
        color:'#fff',
        overflowX:'hidden',
    },
    toolbar: theme.mixins.toolbar,
}));

const DrawerRight = ({ variant, open, onClose }) => {

    const classes = useStyles();
    

    return (
        <Drawer  
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
            variant={variant}
            open={open}
            onClose={onClose ? onClose : null}
        >
            <div className={classes.toolbar}></div>
            <Divider/>
            <ListasRight onClose={onClose} />
        </Drawer>
    )
}

export default DrawerRight;
