import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import MaximizeIcon from '@material-ui/icons/Maximize';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import '../App.css';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ComputerIcon from '@material-ui/icons/Computer';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';


const useStyles = makeStyles((theme) => ({
    box1: {
        height:'450px',
        padding:'70px 20px',
        color:'#fff',
        transition:'all 1s',
        '&:hover': {
            background:'#827717',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.up('xs')]: {
            padding:'20px 20px',
        },
        
    },
    box2: {

        height:'450px',
        padding:'70px 20px',
        color:'#fff',
        transition:'all 1s',
        '&:hover': {
            background:'#aa263a',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.up('xs')]: {
            padding:'20px 20px',
        },
    },
    box3: {
    
        height:'450px',
        padding:'70px 20px',
        color:'#fff',
        transition:'all 1s',
        '&:hover': {
            background:'#16a085',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.up('xs')]: {
            // height:'380px',
            padding:'20px 20px',
        },
    },
    box4: {
        
        height:'450px',
        padding:'70px 20px',
        color:'#fff',
        transition:'all 1s',
        '&:hover': {
            background:'#1c9bd7',
            transition:'all 1s',
            cursor:'pointer',
        },
        [theme.breakpoints.up('xs')]: {
            padding:'20px 20px',
        },
    },
    icon1: {
        color:'#cc6714',
        fontSize:50,

    },
    icon2: {
        color:'#cc6714',
        fontSize:50,

    },
    icon3: {
        color:'#cc6714',
        fontSize:50,

    },
    icon4: {
        color:'#cc6714',
        fontSize:50,

    },
    servIcon: {
        fontSize:'100px',
        color:'#cc6714',

    },
    servIcon1: {
        textAlign:'initial',
        paddingRight:'25px',
        
    },
    titulo: {
        textAlign:'initial',
        paddingTop:'30px',
        fontFamily: 'Ubuntu, sans-serif',
    },
    texto: {
        fontFamily: 'Ubuntu, sans-serif',
    },
    boxCont: {
        display:'flex',
        [theme.breakpoints.only('xs')]: {
            display:'inline-flex',

        },
    }



}));

const PruebaBoxMenu = () => {
    const classes = useStyles();

    return (
        <div>
            <Grid container >
                <Grid item xs={12} sm={6} md={6} lg={3} >
                    <Box className={classes.box1} >
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1}>
                                <Typography variant="h4" className={classes.titulo}>Servicios Telecom</Typography>
                                <MaximizeIcon className={classes.icon1}/>
                            </Box>
                            <Box>
                                <BusinessCenterIcon className={classes.servIcon}/>
                            </Box>
                        </Box>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h6" className={classes.texto}>Terminación de VoIP y SMS; VoIP al por mayor; Rutas CLI; Rutas del centro de llamadas (CC); SMS masivos.</Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                    <Box className={classes.box2}>
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1}>
                                <Typography variant='h4' className={classes.titulo}>Soluciones VoIP</Typography>
                                <MaximizeIcon className={classes.icon2}/>
                            </Box>
                            <Box>
                                <CheckBoxIcon className={classes.servIcon}/>
                            </Box>
                        </Box>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h6" className={classes.texto}>Solución VoIP de nivel de operador (Softswitch de clase 4); Plataforma de SMS independiente; Sistema ERP VoIP.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                    <Box className={classes.box3}>
                        <Box className={classes.boxCont}>
                            <Box className={classes.servIcon1}>
                                <Typography variant='h4' className={classes.titulo}>Plataforma de medios</Typography>
                                <MaximizeIcon className={classes.icon3}/>
                            </Box>
                            <Box>
                                <ComputerIcon className={classes.servIcon}/>
                            </Box>
                        </Box>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h6" className={classes.texto}>Nuevo flujo de ingresos para MNO / MVNO; Monetización de aplicaciones móviles; Canal de publicidad móvil innovador.</Typography>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                    <Box className={classes.box4}>
                    <Box className={classes.boxCont}>
                        <Box className={classes.servIcon1}>
                            <Typography variant='h4' className={classes.titulo}>Desarrollo a la medida</Typography>
                            <MaximizeIcon className={classes.icon4}/>
                        </Box>
                        <Box>
                            <WbIncandescentIcon className={classes.servIcon}/>
                        </Box>
                    </Box>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h6" className={classes.texto}>Software personalizado, desarrollo de sitios web, aplicaciones de servidor, desarrollo móvil, servicios web y soluciones digitales.</Typography>
                    </Box>
                </Grid>
              
            </Grid>
        </div>
    )
}

export default PruebaBoxMenu
