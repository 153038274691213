import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './Styles/styles.css';
import CoreStyles from './Styles/styles.scss';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MaximizeIcon from '@material-ui/icons/Maximize';
import { makeStyles } from '@material-ui/core/styles';
import FallAnimation from 'react-awesome-slider/src/styled/fall-animation/fall-animation.scss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    slider: {
        width:'100%',
        height:'532px',
        [theme.breakpoints.up('xl')]: {
            height:'700px', 
        },
    },
    foto1: {
        position:'relative',
        height:'400px',
        [theme.breakpoints.up('xl')]: {
            margin:'10px 400px 50px 400px',
        },
        [theme.breakpoints.only('lg')]: {
            margin:'90px 90px 50px 200px',
        },
        [theme.breakpoints.only('md')]: {
            margin:'80px 90px 50px 120px',
        },
        [theme.breakpoints.only('sm')]: {
            margin:'60px 90px 50px 90px',
        },
        [theme.breakpoints.only('xs')]: {
            margin:'60px 20px 20px 20px',
        },

    },
    titulo1: {
        color:'#fff',
        // position:'absolute',
        top: '1px',
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('md')]: {
            fontSize:'52px'
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'43px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'32px'
        },
    },
    subtitulo: {
        color:'#fff',
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'23px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'20px'
        },
    },
}));

const Slider = () => {
    const classes = useStyles();
    const  AutoplaySlider  =  withAutoplay ( AwesomeSlider );
    return (
        <div>
            <AutoplaySlider
                className={classes.slider}
                play={true}
                cancelOnInteraction={false}
                interval={5000}
                mobileTouch={true}
                bullets={false}
                animation='fallAnimation'
                cssModule={[CoreStyles, FallAnimation]}
            >
                <div source='/images/foto1.jpg' className={classes.foto1}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h2" className={classes.titulo1}>Soluciones de telecomunicaciones inteligentes</Typography>
                            <ListItemIcon>
                                <MaximizeIcon style={{color:'#00ad99', fontSize:70}}/>
                            </ListItemIcon>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            <Typography variant="h5" className={classes.subtitulo} >Soluciones empresariales y servicios 
                            de telecomunicaciones de alta calidad con 10 años de experiencia</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div source='/images/foto2.jpg' className={classes.foto1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h2" className={classes.titulo1}>Telefonía en la nube que se adapta a tu empresa</Typography>
                        <ListItemIcon>
                            <MaximizeIcon style={{color:'#00ad99', fontSize:70}}/>
                        </ListItemIcon>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="h5" className={classes.subtitulo}>Estás a un minuto de encontrar el mejor 
                        proveedor de servicios de telefonía y telecomunicaciones para empresas</Typography>
                    </Grid>
                </div>
                <div source='/images/foto3.jpg' className={classes.foto1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h2" className={classes.titulo1}>Central telefónica virtual</Typography>
                        <ListItemIcon>
                            <MaximizeIcon style={{color:'#00ad99', fontSize:70}}/>
                        </ListItemIcon>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                            <Typography variant="h5" className={classes.subtitulo}>Innova tu telefonía empresarial 
                            contratando una plataforma PBX en la nube para contactarte con tus empleados y clientes mediante voz</Typography>
                    </Grid>
                </div>
            </AutoplaySlider>
        </div>
    )
}


export default Slider
