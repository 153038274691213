import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import omnitelLogo from '../images/omnitelLogo.png';
import Typography from '@material-ui/core/Typography'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from '@material-ui/icons/Send';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import digitalk from '../images/clientes/digitalk.png';
import google from '../images/clientes/google.png';
import uContact from '../images/clientes/uContact.png';
import ibm from '../images/clientes/ibm.png';
import '../App.css';
import { withRouter } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop:'15px',
      marginRight:'10px',
      overflowY:'hidden',
      overflowX:'hidden',
      background:'#232d38',
      height:'auto',
      [theme.breakpoints.only('xs')]: {
        marginTop:'2px',
        
        },
      
    },    
    paper1: {
        padding:'16px',
        color: theme.palette.text.secondary,
        width:'300px',
        height:'550px',
        background:'transparent',
        [theme.breakpoints.only('sm')]: {
            width:'240px',
        },
        [theme.breakpoints.only('xs')]: {
            height:'420px',
            
        },

    },
    paper2: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width:'280px',
        height:'550px',
        background:'transparent',
        [theme.breakpoints.only('xs')]: {
            height:'480px',
            
        },

    },
    paper3: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width:'330px',
        height:'550px',
        background:'transparent',
        paddingLeft:'60px',
        [theme.breakpoints.only('sm')]: {
            height:'480px',
            paddingLeft:'75px',
            
        },
        [theme.breakpoints.only('xs')]: {
            height:'480px',
            width:'300px',
            paddingLeft:'15px',

        },

    },
    logo: {
        width:200,
        textAlign:'center'
    },
    email: {
        marginTop:"30px",
        width:'150px',
        color:'#fff',
        [theme.breakpoints.only('sm')]: {
            marginLeft:'40px'
        },
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
              color: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
              color: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
              color: 'white',
            },
            
            
        },
    },
    button: {
        marginTop:"40px",
        marginLeft:'10px',
        background:'#cc6714',
        transition:'all 0.5s',
        '&:hover':{
            background:'#cc6714',
            color:'#fff',
            transition:'all 0.6s',
        },
        [theme.breakpoints.only('sm')]: {
            marginTop:'10px',
            marginLeft:'60px'
        },
    },
    texto1: {
        color:'#fff',
        fontSize:'auto',
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('xs')]: {
            fontSize:'17px',
            padding:'0px 10px 0px 0px',
            fontSize:'16px'
        },
    },
    texto2: {
        color:'#fff',
        fontSize:'18px',
        cursor:'pointer',
        fontFamily: 'Ubuntu, sans-serif',
        '&:hover': {
            color:'#16a085',
        }
    },
    texto3: {
        fontFamily: 'Ubuntu, sans-serif',
        color:'#fff',
        fontSize:'18px',
    },
    input: {
        color: '#fafafa',
    },
    
}));

const Footer = ({ history }) => {
    const classes = useStyles();
    const enviarCorreo = () => alert('Enviado')    
    
    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="center" >
                <Grid item xs={12} sm={5} md={4} lg={3} style={{marginTop:'40px'}}>
                    <Box className={classes.paper1}>
                        <img src={omnitelLogo} className={classes.logo} alt="logo" />
                        <Typography variant="body1" align='justify' className={classes.texto1} >
                        OMNITEL NETWORKS SAC es una empresa de Telecomunicaciones fundado por profesionales con más de 10 años de experiencia 
                        en las industrias de desarrollo de software y telecomunicaciones. Si desea obtener más información sobre nuestras 
                        soluciones de telecomunicaciones, póngase en contacto con nuestro equipo para analizar las oportunidades comerciales.
                        </Typography>
                        <TextField
                        id="outlined-search"
                        label="email"
                        type="search"
                        variant="outlined"
                        color='primary'
                        className={classes.email}
                        InputLabelProps={{
                            className: classes.label
                            }}
                        InputProps={{
                            className: classes.input
                          }}
                        />
                        <Button
                        variant="contained"
                        className={classes.button}
                        endIcon={<SendIcon/>}
                        type="submit"
                        onClick={() => enviarCorreo()}
                        >
                        Enviar
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={2} style={{marginTop:'70px'}}>
                    <Box className={classes.paper2}>
                        <Typography variant="h5" style={{color:'#fff', fontWeight:600}}>Sistemas</Typography>
                        <br/>
                        <Typography className={classes.texto2} onClick={() => history.push("/sistemas")}>Marcador Predictivo</Typography>                        
                        <Typography className={classes.texto2} onClick={() => history.push("/sistemas")}>Validador de llamadas</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/sistemas")}>Business Intelligence</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/sistemas")}>Webphone</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/sistemas")}>Robot de llamadas</Typography>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h5" style={{color:'#fff', fontWeight:600}}>Servicios</Typography>
                        <br/>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>SMS Masivo</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>Telefonía</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>IVRs Masivos</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>Random de llamadas</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>DID Numbers</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>Emailing</Typography>
                        <Typography className={classes.texto2} onClick={() => history.push("/servicios")}>Wholesale termination</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3} style={{marginTop:'70px'}} id="contactos">
                    <Box className={classes.paper3}>                        
                        <Typography variant="h5" style={{color:'#fff', fontWeight:600}}>Contactos</Typography>
                        <br/>
                        <Typography className={classes.texto3} ><LocationOnIcon style={{paddingTop:'10px'}} />Los Ficus 175, Santa Anita</Typography>
                        <Typography className={classes.texto3} ><PhoneIcon style={{paddingTop:'10px'}}/>930403671</Typography>
                        <Typography className={classes.texto3} ><EmailIcon style={{paddingTop:'10px'}}/>jascue@omnitel.pe</Typography>
                        {/* <br/>
                        <br/>
                        <br/>
                        <Typography variant="h5" style={{color:'#fff', fontWeight:600}}>Partners</Typography>
                        <br/>
                        <div>
                            <img alt="google" src={google} style={{width:100, marginTop:'25px'}} />
                            <img alt="ibm" src={ibm} style={{width:100, marginTop:'25px', marginLeft:'30px'}} />
                        </div>
                        <div>
                            <img alt='uContact' src={uContact} style={{width:100, marginTop:'25px'}}/>
                            <img alt="pompiTalk" src={digitalk} style={{width:150, marginTop:'25px', marginLeft:'30px'}} />
                        </div> */}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(Footer)