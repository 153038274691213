import React from 'react'
import omnitelLogo from '../images/omnitelLogo.png';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles(( theme ) => ({
    root: {
        display: 'flex',
    },
    appBar:{
        // background: 'linear-gradient(to left, #1f4037, #99f2c8)',
        background:'#232d38',
        display:'flex',
        width:'100%',
        [theme.breakpoints.up('xs')]: {
            height:'auto',
        },
    },
    
    logo:{
        width:80,
        marginLeft:'20px',
        cursor:'pointer',
        [theme.breakpoints.only('xs')]: {
            marginLeft:'0px',
        },
    },
    boton:{
        marginLeft:'25px',
        fontWeight:600,
        // display:'block',
        color:'#fff',
        fontFamily: 'Ubuntu, sans-serif',
        '&:hover':{
          borderBottom:'solid 4px #cc6714',
    
        }
        
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },

    toolbar: theme.mixins.toolbar,
    menu: {
        transform:'translateY(150px)'
    }


}));

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
}
  
const AppBarMenu = ({ history, accionAbrir }) => {
    const classes = useStyles();
    
    const toDown = () => {
        
    }

    return (
        <HideOnScroll>
            <AppBar position="fixed" className={classes.appBar}>                
                <Toolbar >
                    <img src={omnitelLogo} className={classes.logo} onClick={() => history.push("/")}/>
                    <Hidden smDown>
                        <div className={classes.root} />                    
                        <div>
                            <Button className={classes.boton} onClick={() => history.push("/")} >Presentacion</Button>
                        </div>
                        <div>
                            <Button className={classes.boton} endIcon={<ChevronRightIcon/>} aria-haspopup="true" onClick={() => history.push("/servicios")}>Servicios</Button>                        
                        </div>
                        <div>
                            <Button className={classes.boton} endIcon={<ChevronRightIcon/>} aria-haspopup="true" onClick={() => history.push("/sistemas")}>Sistemas</Button>                        
                        </div>
                        <div>
                            <Button className={classes.boton} href="#contactos">Contactos</Button>
                        </div>
                    </Hidden>
                    <div className={classes.grow} />
                    <IconButton
                        color="inherit"
                        className={classes.menuButton}
                        onClick={() => accionAbrir()}            
                    >
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    )
}

export default withRouter(AppBarMenu);
