import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        background:'#232d38',
        padding:'50px 100px',
        justify:'center',
        alignItems:'center',
        [theme.breakpoints.only('xs')]: {
            padding:'5px 20px',
               
        },
        [theme.breakpoints.only('md')]: {
            padding:'50px 100px 0px 100px',
               
        },
    },

    box1: {
        padding:'50px 0px',
        flexGrow:1,
        color:'#fff',
        width:'200px',
        marginRight:'80px',
        fontSize:'20px',
        [theme.breakpoints.only('xs')]: {
            marginRight:'10px',
            padding:'20px 0px'     
        },
        [theme.breakpoints.only('sm')]: {
            marginRight:'0px',
            padding:'0px 0px 50px 0px'     
        },
        [theme.breakpoints.only('md')]: {
            marginRight:'0px',
            padding:'0px 0px 50px 0px'     
        },
        [theme.breakpoints.only('lg')]: {
            marginRight:'0px',
            padding:'0px 0px 50px 0px'     
        },
    },
    box2: {
        textAlign: 'center',        
        maxWidth: 400,
        [theme.breakpoints.only('xs')]: {
            marginBottom:'20px'
        },
        [theme.breakpoints.only('md')]: {
            padding:'0px 0px 50px 0px'     
        },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(1),
      backgroundColor: '#2c3846',      
    },
    img: {
      height: 255,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    texto: {
        fontSize:'20px',
        flexGrow: 1,
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('xs')]: {
            fontSize:'15px',   
        },
        [theme.breakpoints.only('md')]: {
            paddingRight:'50px',
            fontSize:'18px',
        },
        [theme.breakpoints.only('lg')]: {
            paddingRight:'80px',
        },
    },
    titulo: {
        fontFamily: 'Satisfy, cursive',
        color:'#00ad99',
        marginBottom:'30px',
        [theme.breakpoints.only('md')]: {
            fontSize:'40px',
            marginBottom:'15px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'30px',
            marginBottom:'15px',
            textAlign:'center',
        },
    },
    tituloHeader: {
        fontFamily: 'Ubuntu, sans-serif',
        color:'#fff',
        fontSize:'17px',
        margin: 'auto',

    }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'Eficiencia operacional',
    imgPath:'https://www.digitalk.com/images/carrier-cloud-banner.jpg',
  },
  {
    label: 'Comprometidos con el éxito de los socios',
    imgPath:'https://www.digitalk.com/images/professional-services-banner.jpg',
  },
  {
    label: 'Una solución completa para servicios de telefonía',
    imgPath:'https://www.digitalk.com/images/consumer-cloud-banner.jpg',
  },
];

const Stepper = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    return (
        <Grid container className={classes.root} justify='center' alignItems='center' >
            <Grid item xs={12} sm={12} md={6} lg={8} className={classes.box1} >
                <Typography variant="h3" className={classes.titulo}> Sistemas de alto nivel</Typography>
                <Typography variant="body1" align='justify' className={classes.texto} >Contamos con sistemas dinámicos, amigables e integrables con cualquier 
                plataforma de Telefonía. para ello nos soportamos en el Know How que tenemos en nuestra infraestructura 
                tecnológica adaptándonos al requerimiento de nuestros clientes. A su vez también contamos con valores 
                agregados mediante plataformas de comunicaciones “In House” que nos permite mejorar la contactabilidad 
                de las gestiones telefónicas.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className={classes.box2} >
                <Paper square elevation={0} className={classes.header}>
                    <Typography className={classes.tituloHeader}>{tutorialSteps[activeStep].label}</Typography>
                </Paper>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {tutorialSteps.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                        <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    style={{background:'#232d38', color:'#232d38'}}
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                    <Button style={{background:'#232d38', color:'#232d38'}} size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                    }
                    backButton={
                    <Button style={{background:'#232d38', color:'#232d38'}} size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                    </Button>
                    }
                />
            </Grid>
        </Grid>
    )
}

export default Stepper
