import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import '../App.css';

const useStyles = makeStyles((theme) => ({

    titulo1: {
        textAlign:'center',
        fontSize:'30px',
        fontWeight:600,
        padding:'40px 100px',
        marginBottom:'50px',
        fontFamily: 'Ubuntu, sans-serif',
        color:'#FFF',
        // textAlign:'justify',
        [theme.breakpoints.up('xl')]: {
            fontSize:'45px',
            padding:'50px 130px',
            // height:'250px',
        },
        [theme.breakpoints.only('lg')]: {
            fontSize:'40px',
            padding:'50px 100px',
        },
        [theme.breakpoints.only('md')]: {
            fontSize:'35px',
            padding:'50px 100px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'28px',
            padding:'10px 50px',
            marginBottom:'45px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'22px',
            fontWeight:400,
            padding:'10px 40px',
            marginBottom:'35px',
        },

    },
    omnitel: {
        fontFamily: 'Satisfy, cursive',
        fontSize:50,
        color:'#00ad99',
        transition:'all 0.3s',
        '&:hover': {
            color:'#08ddc4',
            cursor:'pointer',
            transition:'all 0.3s'
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'35px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'25px',
        },
    },
    GridsMenu: {
        [theme.breakpoints.up('md')]: {
            marginTop:'40px',
        },
        [theme.breakpoints.only('sm')]: {
            marginTop:'30px',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop:'30px',
        },
    }
    
}));

const GridsMenu = () => {
    const classes = useStyles();

    return (
        <div className={classes.GridsMenu}>
            <Typography className={classes.titulo1}>
            <span className={classes.omnitel}>Omnitel Networks</span> ofrece una amplia gama de soluciones de software y telecomunicaciones 
            para cumplir con los estándares industriales de vanguardia de varias empresas
            </Typography>
        </div>
    )
}

export default GridsMenu
