import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import '../App.css';
import BusinessIcon from '@material-ui/icons/Business';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ContactsIcon from '@material-ui/icons/Contacts';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(( theme ) => ({
    nested: {
        paddingLeft: theme.spacing(4),

        '&:hover':{
            transform:'translateX(10px)',
            transition:'all 0.4s',
            color:'#08ddc4',
        }
    },
    viñetas: {
        color:'#fff',
    },
    lista: {
        textAlign:'left',
        marginLeft:'-25px',

    },
    tipo: {
        fontFamily: 'Ubuntu, sans-serif',
    }
}));




const ListasRight = ({ history, onClose }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [abrir, setAbrir] = useState(false);
    
    const handleClick = () => {
        setOpen(!open);
    };
    const handleClickTwo = () => {
        setAbrir(!abrir);
    };    
    
    const llevame = ( ) => {
        window.scrollTo(0,8000);
        onClose()
    }

    return (
        <div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
                
                >
                <ListItem button>
                    <ListItemIcon>
                    <BusinessIcon style={{color:'#fff'}} />
                    </ListItemIcon>
                    <ListItemText primary="Presentación" className={classes.tipo} onClick={() => history.push("/")}/>
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                    <ListAltIcon style={{color:'#fff'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Servicios" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="SMS Masivo" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Telefonía" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="IVRs Masivos" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Randon de llamadas" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="DID Numbers" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Emailing" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/servicios")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Wholesale termination" className={classes.lista}/>
                    </ListItem>
                    </List>
                </Collapse>
                <ListItem button onClick={handleClickTwo}>
                    <ListItemIcon>
                    <BeenhereIcon style={{color:'#fff'}}/>
                    </ListItemIcon >
                    <ListItemText primary="Sistemas" />
                    {abrir ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={abrir} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <ListItem button className={classes.nested} onClick={() => history.push("/sistemas")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Marcador Predictivo" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/sistemas")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Validador de llamadas" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/sistemas")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Business Intelligence" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/sistemas")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Webphone" className={classes.lista}/>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => history.push("/sistemas")}>
                        <ListItemIcon>
                        <ArrowRightIcon className={classes.viñetas}/>
                        </ListItemIcon>
                        <ListItemText primary="Robot de llamadas" className={classes.lista}/>
                    </ListItem>
                    </List>
                </Collapse>
                <ListItem button>
                    <ListItemIcon>
                    <ContactsIcon style={{color:'#fff'}} />
                    </ListItemIcon>
                    <ListItemText primary="Contactos" className={classes.tipo} onClick={() => llevame()} />                    
                </ListItem>
            </List>
        </div>
    )
}

export default withRouter(ListasRight);
