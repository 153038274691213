import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({    
    root: {
      flexGrow: 1,      
      backgroundColor: '#232d38',    
      overflow: 'hidden',
      marginTop:'90px',
      marginBottom:'15px',
    },  
    boxInPaper: {                  
      [theme.breakpoints.only('xs')]: {
        marginBottom: '-5px'
      },
      [theme.breakpoints.only('sm')]: {
        marginBottom: '-5px'
      },      
      [theme.breakpoints.only('md')]: {
        height: '220px',
        marginBottom: '-100px',
      },
      [theme.breakpoints.only('lg')]: {
        height: '220px',
        marginBottom: '-100px',
      },
      [theme.breakpoints.only('xl')]: {
        height: '220px',
        marginBottom: '-120px', //- para subir subraya
      },
    },  
    texto: {
      fontFamily: 'Ubuntu, sans-serif',            
      color:'#fff',            
      [theme.breakpoints.only('xs')]: {        
        marginTop: '-70px',
        height: '290px',
        fontSize:'18px',
      },
      [theme.breakpoints.only('sm')]: {        
        marginTop: '-70px',
        height: '290px',
        fontSize:'17px', 
      },
      [theme.breakpoints.only('md')]: {        
        marginTop: '-70px',
        height: '290px', 
        fontSize:'17px',       
      },
      [theme.breakpoints.only('lg')]: {        
        marginTop: '-70px',
        height: '250px',
      },
      [theme.breakpoints.only('xl')]: {
        marginTop: '-70px',
        height: '180px',        
      },
      // [theme.breakpoints.up('sm')]: {
      //   height: '100px'
      // },
      // padding:'-50px 50px 50px 20px',
    },    
    titulo: {
      textAlign:'initial',
      paddingTop:'30px',
      fontFamily: 'Ubuntu, sans-serif',
      color:'#fff',
    },
    
    subraya: {
      textAlign:'initial',
      // paddingRight:'25px',
      fontSize: 80,
      color:'#2C7744',
      // paddingBottom: '-35px'
      
    },
    icon: {
      textAlign:'initial',
      color:'#cc6714',

    },
    iconEmail: {
      textAlign:'initial',
      color:'#cc6714',
      [theme.breakpoints.only('md')]: {        
        marginRight: '-15px',
      },

    },
    servIcon: {
      fontSize:'100px',
      textAlign:'end',
    },
    
    paper1: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',      
      '&:hover': {
        background:'#4BC0C8',
        transition:'all 1.4s',
        cursor:'pointer',
          
      }
    },
    paper2: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',       
      '&:hover': {
        background:'#4BC0C8',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
    paper3: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',      
      '&:hover': {
        background:'#827717',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
    paper4: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',      
      '&:hover': {
        background:'#aa263a',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
    paper5: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',      
      '&:hover': {
        background:'#827717',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
    paper6: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',      
      '&:hover': {
        background:'#4BC0C8',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
    paper7: {
      // margin: '20px 15px -25px 10px',
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: '#2c3846',
      transition:'all 1.4s',       
      '&:hover': {
        background:'#aa263a',
        transition:'all 1.4s', 
        cursor:'pointer',
          
      }
    },
        
}));

export default useStyles;