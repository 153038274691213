import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './App.css';
import GridsMenu from '../src/Component/GridsMenu';
import SobreNosotros from './Component/SobreNosotros';
import Slider from './Component/Slider';
import PruebaBoxMenu from './Component/PruebaBoxMenu';
import Stepper from './Component/Stepper';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fondo:{
    width:'100%',
    opacity:'90%',
    '&:hover':{
      opacity:'100%',
      transition:'all 0.5s',
    }
  },
  body: {
    background:'#2c3846',
    overflowX:'hidden',
  },
  
}));


function App({ abrir, accionAbrir }) {
  const classes = useStyles();  
  
  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  return (
    <div className={classes.body}>
      <Grid container>
        <Grid item xs={12}>
          <Slider/>
        </Grid>
      </Grid>      
      <GridsMenu/>
      <PruebaBoxMenu/>
      <Stepper/>
      <SobreNosotros/>
    </div>
  );
}

export default App;
