import React from 'react';
import Typography from '@material-ui/core/Typography';
import MaximizeIcon from '@material-ui/icons/Maximize';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import about from '../images/about.jpg';

const useStyles = makeStyles((theme) => ({
    texto: {
        color:'#fff',
        marginTop:'50px',
        marginBottom:'130px',
        paddingRight:'130px',
        paddingLeft:'100px',
        [theme.breakpoints.only('xs')]: {
            fontSize:'10px',
            padding:'5px 20px',
        
        },
        
    },
    texto1: {
        fontFamily: 'Ubuntu, sans-serif',
        fontWeight:600,
        [theme.breakpoints.only('xs')]: {
            fontSize:'20px',
            
        },
    },
    texto2: {
        fontFamily: 'Ubuntu, sans-serif',
        fontSize:'20px',
        [theme.breakpoints.only('xs')]: {
            fontSize:'15px', 
        },
        [theme.breakpoints.only('md')]: {
            fontSize:'18px', 
        },
    },
    pCalls: {
        fontFamily: 'Satisfy, cursive',
        fontSize:40,
        color:'#00ad99',
        transition:'all 0.3s',
        '&:hover': {
            color:'#08ddc4',
            cursor:'pointer',
            transition:'all 0.3s'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'28px', 
        },
    },
    about: {
        width:'auto',
        height:'523px',
        [theme.breakpoints.up('lg')]: {
            width:'100%', 
        },
    },
    todotexto: {
        color:'#fff',
        position:'absolute',
        top: '40px',
        padding:'70px 70px 100px 150px',
        [theme.breakpoints.only('xs')]: {
            padding:'70px 50px 50px 50px',  
        },
        [theme.breakpoints.only('sm')]: {
            padding:'70px 130px 100px 100px',  
        },
        [theme.breakpoints.only('md')]: {
            padding:'70px 0px 100px 100px',  
        },
        [theme.breakpoints.up('lg')]: {
            padding:'70px 300px 100px 100px',  
        },

    },
    sobrefoto: {
        position:'relative',
    },
    titulo: {
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('md')]: {
            fontSize:'50px',
        },
        [theme.breakpoints.only('sm')]: {
            fontSize:'40px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'29px',
        },
        
        
    },
    subtitulo: {
        fontFamily: 'Ubuntu, sans-serif',
        [theme.breakpoints.only('sm')]: {
            fontSize:'22px',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'18px',
        },
    }




}));


const SobreNosotros = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.sobrefoto}>
                <img src={about} className={classes.about}/>
                <Grid container spacing={1} className={classes.todotexto} >
                    <Grid item xs={12} sm={12} md={12} >
                        <Typography variant="h2" className={classes.titulo}>Habilitando la innovación</Typography>
                        <ListItemIcon>
                            <MaximizeIcon style={{color:'#00ad99', fontSize:60}}/>
                        </ListItemIcon>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="h5" className={classes.subtitulo} align='justify'>Un especialista en plataformas 
                        de comunicaciones en tiempo real basadas en la nube para operadores de redes fijas y móviles, 
                        operadores y proveedores de servicios.</Typography>
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={1} className={classes.texto} >
                <Grid item xs={12} sm={12} md={4} >
                    <Typography variant="h4" className={classes.texto1}>Sobre <span className={classes.pCalls}>Omnitel Networks</span></Typography>
                    <ListItemIcon>
                        <MaximizeIcon style={{color:'#00ad99', fontSize:50}}/>
                    </ListItemIcon>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="h6" className={classes.texto2} align='justify'>OMNITEL NETWORKS SAC es una empresa de Telecomunicaciones fundado por profesionales con más de 10 años de experiencia en el sector Telecom a nivel Nacional e Internacional. 
                    Somos una empresa peruana especializada en ofrecer servicios Telefonía IP vía nuestra Plataforma Carrier Cloud Integrada & BI Dinámica que nos permite un mejor 
                    funcionamiento estadístico en el requerimiento de Reportería en Telefonía al sector corporativo de Ventas, Encuestas y Cobranzas. Contamos los mejores sistemas y plataformas de telecomunicaciones cumpliendo con los estándares de reportería para Telefonía.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default SobreNosotros
