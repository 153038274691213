import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Container from './Container'

ReactDOM.render(  
    <Container />,
  document.getElementById('root')
);
